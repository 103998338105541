import type { TaskItemStatus } from '@app/api/editor';

import { type TranslationDTO, convertMismatchDataDTOtoModel } from '../../../types/translation';
import type Translation from '../../../types/translation';

const parseTranslation = (translationProp: string | undefined): Translation | null => {
    if (!translationProp) {
        return null;
    }

    /**
     * The translation editor may be rendered either by the PHP backend, or dynamically on the frontend,
     * using direct twig render. Due to the differences in these two implementations, the escaping on the
     * translation prop is different between two render methods. The following line will handle any
     * escaping and return a properly formatted JSON string.
     */
    const decodedTranslationProp = new DOMParser().parseFromString(translationProp, 'text/html').documentElement
        .textContent;

    if (!decodedTranslationProp) {
        return null;
    }

    const translationDto = JSON.parse(decodedTranslationProp) as TranslationDTO;

    const translation: Translation = {
        translationId: parseInt(translationDto.id, 10),
        keyId: parseInt(translationDto.akey_id, 10),
        projectId: translationDto.project_id,
        isPlural: translationDto.is_plural === '1',
        qaResults: translationDto.qa_results ?? [],
        mismatchResults: convertMismatchDataDTOtoModel(translationDto.p_mismatch_data),
        activeTask: {
            taskId: parseInt(translationDto.active_task?.task_id, 10) ?? 0,
            taskItemId: parseInt(translationDto.active_task?.task_item_id, 10) ?? 0,
            taskItemStatus: translationDto.active_task?.task_item_status as TaskItemStatus,
        },
        segmentNumber: parseInt(translationDto.segment_number, 10) ?? 0,
        translationVerified: translationDto.fuzzy === '0',
        reviewed: translationDto.is_proofread === '1',
        value: translationDto.translation,
        sourceValue: translationDto.base_trans,
        isBaseLanguage: translationDto.is_default === '1',
        langId: translationDto.lang_id,
    };

    return translation;
};

export default parseTranslation;
