import useSelector from '@app/hooks/useSelector';
import { getFeatureState } from '@app/selectors';

export type Feature =
    | 'projectsAsyncStats'
    | 'homeFeatureLqai'
    | 'homeFeatureStyleGuide'
    | 'lionLfcFilesManagement'
    | 'lionFeatureFilesManagementBulkActions'
    | 'trexFeatureAutomaticTranslations'
    | 'perfFeatureReportingEngine'
    | 'homeFeatureAiLqaSuggestions'
    | 'homeFeatureAibilling'
    | 'homeBugfixAiTaskSaveTemplate'
    | 'homeFeatureAutomaticTranslationUse100TMMatch'
    | 'homeFeatureAutomaticTranslationUseFuzzyMatch'
    | 'homeMarketingBeta'
    | 'growthSubscriptionExtras'
    | 'growthCreditCardExpiry'
    | 'growthCancellationFlow'
    | 'metroWorkflowsManagement'
    | 'metroWorkflowsGa'
    | 'metroFeatureTemplateTriggerGates'
    | 'maxMigrationForm'
    | 'gitLabRecursivePullEnabled'
    | 'perfFeatureAsyncExport'
    | 'exportAppleStringsReplaceU2028Disabled'
    | 'translationsFeatureAiScoring';

export const useFeatureState = (feature: Feature): string | boolean | undefined => {
    const featureState = useSelector((state) => getFeatureState(state.features, feature));

    return featureState !== null ? featureState : undefined;
};
