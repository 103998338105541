import type { TaskItemStatus } from '@app/api/editor';

export interface TranslationDTO {
    id: string;
    akey_id: string;
    project_id: string;
    qa_results: readonly QaResult[];
    is_plural: string;
    active_task: { task_id: string; task_item_id: string; task_item_status: string };
    p_mismatch_data: MismatchDataDTO;
    p_mismatch: 0 | 1;
    segment_number: string;
    fuzzy: '0' | '1';
    is_default: '0' | '1';
    base_trans: string;
    is_proofread: '0' | '1';
    translation: string;
    is_locked_for_order: '0' | '1';
    is_locked_for_task: '0' | '1';
    lang_id: string;
}

export interface MismatchDataDTO {
    missing?: MismatchGroupDataDTO;
    excess?: MismatchGroupDataDTO;
}

type MismatchGroupDataDTO = {
    tag?: readonly MismatchItemsDTO[];
    placeholder?: readonly MismatchItemsDTO[];
    icu?: readonly MismatchItemsDTO[];
};

interface MismatchItemsDTO {
    [key: string]: { count?: number; id?: string; type: string };
}

export interface MismatchResults {
    missing?: MismatchGroupData;
    excess?: MismatchGroupData;
}

export type MismatchGroupData = {
    tag?: readonly MismatchItem[];
    placeholder?: readonly MismatchItem[];
    icu?: readonly MismatchItem[];
};

export interface MismatchItem {
    key: string;
    count?: number;
    id?: string;
    type?: string;
}

export interface QaResult {
    levelCode: number;
    test: number;
    title: string;
}

export default interface Translation {
    translationId: number;
    keyId: number;
    projectId: string;
    qaResults: readonly QaResult[];
    isPlural: boolean;
    isLocked?: boolean;
    mismatchResults: MismatchResults;
    activeTask: {
        taskId: number;
        taskItemId: number;
        taskItemStatus: TaskItemStatus;
    };
    segmentNumber: number;
    translationVerified: boolean;
    reviewed: boolean;
    isBaseLanguage: boolean;
    value: string;
    sourceValue: string;
    langId: string;
}

export const convertMismatchDataDTOtoModel = (mismatchData: MismatchDataDTO = {}) => {
    let mismatchResults: MismatchResults = {};

    (Object.entries(mismatchData) as [keyof MismatchDataDTO, MismatchGroupDataDTO][]).forEach(
        ([mismatchGroupKey, mismatchGroup]) => {
            if (mismatchGroup) {
                (Object.entries(mismatchGroup) as [keyof MismatchGroupDataDTO, MismatchItemsDTO[]][]).forEach(
                    ([mismatchDataTypeKey, mismatchDataType]) => {
                        const mismatchDataItems =
                            mismatchDataType &&
                            Object.entries(mismatchDataType).map(([originalKey, value]) => ({
                                ...value,
                                key: originalKey,
                            }));

                        mismatchResults = {
                            ...mismatchResults,
                            [mismatchGroupKey]: {
                                ...mismatchResults[mismatchGroupKey],
                                [mismatchDataTypeKey]: mismatchDataItems,
                            },
                        };
                    },
                );
            }
        },
    );

    return mismatchResults;
};
